import React, { useEffect } from 'react'

export default function About() {
  useEffect(() => {
    document.title = "About - BarakahByte";
  }, []);
  return (
    <>
      <section id="about" className="about section-bg">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>About</h2>
            <h3>Find Out More <span>About Us</span></h3>
            <p>
              Innovating solutions for business success with integrity and expertise.
            </p>
          </div>

          <div className="row">
            <div className="col-lg-6" data-aos="fade-right" data-aos-delay="100">
              <img src="assets/img/about.jpg" className="img-fluid" alt="About Template" />
            </div>
            <div className="col-lg-6 pt-4 pt-lg-0 content d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="100">
              <h3>BarakahByte: Innovative IT Solutions for Business Success</h3>
              <p className="fst-italic">
                At BarakahByte, we're not just another IT solutions company. 
                We're a team of passionate technologists, innovators, and problem solvers dedicated to driving digital transformation for 
                businesses across industries. Since our inception, we've been committed to delivering cutting-edge technology solutions that not 
                only meet but exceed the evolving needs of our clients.
              </p>
              <ul>
                <li>
                  <i className="bx bx-store-alt"></i>
                  <div>
                    <h5><a href="https://bbay.barakahbyte.in" target="_blank" rel="noopener noreferrer">BarakahBay</a> E-commerce Platform</h5>
                    <p>Streamline your grocery shopping experience with our intuitive and user-friendly e-commerce platform.</p>
                  </div>
                </li>
                <li>
                  <i className="bx bx-images"></i>
                  <div>
                    <h5>Custom Software Development</h5>
                    <p>Leverage the power of tailored software solutions to optimize your business processes and drive efficiency.</p>
                  </div>
                </li>
              </ul>
              <p>
                When you choose BarakahByte as your IT partner, you can trust that you're choosing excellence, reliability, and innovation. 
                We're dedicated to delivering results that matter, empowering your business to thrive in today's rapidly changing digital landscape.
                Ready to take your business to new heights? Contact us today to learn more about how BarakahByte can help you harness the 
                power of technology to achieve your goals.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="counts" className="counts">
        <div className="container" data-aos="fade-up">

          <div className="row">

            <div className="col-lg-3 col-md-6">
              <div className="count-box">
                <i className="bi bi-emoji-smile"></i>
                <span data-purecounter-start="0" data-purecounter-end="10" data-purecounter-duration="1" className="purecounter">10</span>
                <p>Happy Clients</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 mt-5 mt-md-0">
              <div className="count-box">
                <i className="bi bi-journal-richtext"></i>
                <span data-purecounter-start="0" data-purecounter-end="2" data-purecounter-duration="1" className="purecounter">2</span>
                <p>Projects</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 mt-5 mt-lg-0">
              <div className="count-box">
                <i className="bi bi-headset"></i>
                <span data-purecounter-start="0" data-purecounter-end="1463" data-purecounter-duration="1" className="purecounter">1463</span>
                <p>Hours Of Support</p>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 mt-5 mt-lg-0">
              <div className="count-box">
                <i className="bi bi-people"></i>
                <span data-purecounter-start="0" data-purecounter-end="1" data-purecounter-duration="1" className="purecounter">1</span>
                <p>Our Team</p>
              </div>
            </div>

          </div>

        </div>
      </section>
    </>
  )
}
