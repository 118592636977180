import React from 'react'

export default function AuthFooter() {
  return (
    <footer id="footer">
        <div className="container py-4">
            <div className="copyright">
                &copy; Copyright 2024 <strong><span>Barakah</span>Byte</strong>. All Rights Reserved
            </div>
            <div className="credits">
                Designed by <a href="https://barakahbyte.in/">BarakahByte</a>
            </div>
        </div>
    </footer>
  )
}
