import React from 'react';
import NavBar from './NavBar';
import TopBar from './TopBar';
import Footer from './Footer';

export default function PublicLayout({ children }) {
  return (
    <>
      <TopBar />
      <NavBar />
      <main>{children}</main>
      <Footer />
    </>
  );
}