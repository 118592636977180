import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

export default function Home() {
    useEffect(() => {
        document.title = "BarakahByte";
    }, []);
  return (
    <>
        <section id="hero" className="d-flex align-items-center">
            <div className="container" data-aos="zoom-out" data-aos-delay="100">
                <h1>Welcome to <span>Barakah</span>Byte<span>.</span></h1>
                <h2>"Empowering Solutions, Enabling Success: <span>Barakah</span>Byte, Where Innovation Meets Excellence!"</h2>
                <div className="d-flex">
                    <Link to="/RequestQuotes" className="btn-get-started">Get Started</Link>
                    {/* <a href="#" className="glightbox btn-watch-video"><i className="bi bi-play-circle"></i><span>Watch Video</span></a> */}
                </div>
            </div>
        </section>
        <section className="featured-services">
            <div className="container" data-aos="fade-up">
                <div className="row">
                    <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                        <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
                        <div className="icon"><i className="bx bxl-dribbble"></i></div>
                        <h4 className="title">About Us</h4>
                        <p className="description">Leading IT Solutions Provider.</p>
                    </div>
                </div>

                <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                    <div className="icon-box" data-aos="fade-up" data-aos-delay="200">
                        <div className="icon"><i className="bx bx-file"></i></div>
                        <h4 className="title">Our Services</h4>
                        <p className="description">Tailored IT Solutions & Support.</p>
                    </div>
                </div>

                <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                    <div className="icon-box" data-aos="fade-up" data-aos-delay="300">
                        <div className="icon"><i className="bx bx-tachometer"></i></div>
                        <h4 className="title">Our Mission</h4>
                        <p className="description">Empowering Business Growth Through Tech.</p>
                    </div>
                </div>

                <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                    <div className="icon-box" data-aos="fade-up" data-aos-delay="400">
                        <div className="icon"><i className="bx bx-world"></i></div>
                            <h4 className="title">Why Choose Us</h4>
                            <p className="description">Expertise, Reliability, Results.</p>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    </>
  )
}
