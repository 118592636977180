import React, { useState } from 'react'
import { Link } from 'react-router-dom'

export default function NavBar() {
  const [activePage, setActivePage] = useState('home');
  const [isNavOpen, setIsNavOpen] = useState(false);
  function showMobileNavBar(){
    isNavOpen?setIsNavOpen(false):setIsNavOpen(true);
  }
  function hideMobileNavBar(){
    setIsNavOpen(false);
  }
  return (
    <header id="header" className="d-flex align-items-center">
        <div className="container d-flex align-items-center justify-content-between">
            <h1 className="logo"><Link to="/"><span>Barakah</span>Byte<span>.</span></Link></h1>
            {/* Uncomment below if you prefer to use an image logo
            <a href="index.html" className="logo"><img src="assets/img/logo.png" alt=""></a> */}
            <nav id="navbar" className={`navbar ${(isNavOpen)? 'navbar-mobile': ''}`}>
                <ul>
                    <li onClick={hideMobileNavBar}><Link className={`nav-link ${activePage === 'home' ? 'active' : ''}`} to="/" onClick={()=>setActivePage('home')}>Home</Link></li>
                    <li onClick={hideMobileNavBar}><Link className={`nav-link ${activePage === 'about' ? 'active' : ''}`} to="/AboutUs" onClick={()=>setActivePage('about')}>About</Link></li>
                    <li onClick={hideMobileNavBar}><Link className={`nav-link ${activePage === 'services' ? 'active' : ''}`} to="/OurServices" onClick={()=>setActivePage('services')}>Services</Link></li>
                    <li onClick={hideMobileNavBar}><Link className={`nav-link ${activePage === 'teams' ? 'active' : ''}`} to="/Teams" onClick={()=>setActivePage('teams')}>Team</Link></li>
                    <li onClick={hideMobileNavBar}><Link className={`nav-link ${activePage === 'contact' ? 'active' : ''}`} to="/ContactUs" onClick={()=>setActivePage('contact')}>Contact</Link></li>
                    <li onClick={hideMobileNavBar}><Link className={`nav-link ${activePage === 'login' ? 'active' : ''}`} to="/Login" onClick={()=>setActivePage('login')}>Login</Link></li>
                </ul>
                <i className={`bi ${(isNavOpen)? 'bi-x': 'bi-list'} mobile-nav-toggle`} onClick={showMobileNavBar}></i>
            </nav>
        </div>
    </header>
  )
}
